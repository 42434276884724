// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-you-tsx": () => import("./../../../src/pages/about-you.tsx" /* webpackChunkName: "component---src-pages-about-you-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-existing-user-tsx": () => import("./../../../src/pages/existing-user.tsx" /* webpackChunkName: "component---src-pages-existing-user-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-success-tsx": () => import("./../../../src/pages/join-success.tsx" /* webpackChunkName: "component---src-pages-join-success-tsx" */),
  "component---src-pages-membership-agreement-tsx": () => import("./../../../src/pages/membership-agreement.tsx" /* webpackChunkName: "component---src-pages-membership-agreement-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-your-contribution-tsx": () => import("./../../../src/pages/your-contribution.tsx" /* webpackChunkName: "component---src-pages-your-contribution-tsx" */),
  "component---src-pages-your-work-tsx": () => import("./../../../src/pages/your-work.tsx" /* webpackChunkName: "component---src-pages-your-work-tsx" */)
}

