import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'

import { FormPage } from '../types/FormPage'
import { ActionPageDataInput } from '../types/ActionPageDataInput'
import { FieldData } from '../types/FieldData'
import FormDataType from 'components/types/FormDataType'

import {
  ADDRESS, UNIT_NUMBER, CITY, STATE_OR_TERITORY, POSTCODE, GENDER, EMPLOYMENT_STATUS,
  DATE_OF_BIRTH, EMPLOYMENT_STATUS_CASUAL, YOUR_NEXT_OF_KIN_DATA, KIN_FIRST_NAME,
  KIN_LAST_NAME, KIN_PHONE_NUMBER, KIN_RELATION, KIN_ADDRESS, KIN_UNIT_NUMBER,
  KIN_CITY, KIN_STATE_OR_TERITORY, KIN_POSTCODE, GUARDIAN_DATA,
  GUARDIAN_FIRST_NAME, GUARDIAN_LAST_NAME, GUARDIAN_PHONE_NUMBER, GUARDIAN_EMAIL
} from '../types/AboutYouField'

import {
  FIRST_NAME, LAST_NAME, PHONE_NUMBER, EMAIL, SUBURB_WORK, EMPLOYER, WORK_PLACE_POSTCODE,
  CANT_FIND_SUBURB_SELECTED, STATE_FROM_SUBURB, EMPLOYER_SELECTED_OR_TYPED, PAYROLL_NUMBER_REQUIRED,
  PAYROLL_DEDUCTION_REQUIRED
} from '../types/YourWorkField'

import { PRE_EXISTING_ISSUES, MEMBERSHIP_AGREEMENT } from '../types/MembershipsField'

import {
  BILLING_ADDRESS_OPTION,
  BILLING_FIRST_NAME,
  BILLING_LAST_NAME,
  BILLING_ADDRESS,
  BILLING_UNIT_NUMBER,
  BILLING_CITY,
  BILLING_STATE_OR_TERITORY,
  BILLING_POSTCODE,
  PAYMENT_METHOD_SELECTION,
  CREDITCARD_PAYMENT_TAB_DATA,
  BANKACCOUNT_PAYMENT_TAB_DATA,
  PAY_DEDUCTION_PAYMENT_TAB_DATA,
  BANKACCOUNT_PAYMENT_ACCOUNT_NAME,
  BANKACCOUNT_PAYMENT_BSB,
  BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER,
  BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION,
  BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION,
  CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER,
  CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE,
  CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV,
  CREDITCARD_PAYMENT_DATE_OF_DEDUCTION,
  CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION,
  CREDITCARD_FATZEBRA_AUTHKEY,
  CREDITCARD_FATZEBRA_TOKEN,
  CREDITCARD_PAYWAY_TOKEN,
  PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE,
  PAY_DEDUCTION_PAYMENT_NUMBER,
  DIRECT_DEBIT_AGREEMENT,
  BANKACCOUNT_AGREE_TERM_AND_CONDITION,
  CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION,
  DIFFERENT_BILLING_ADDRESS_DATA
} from '../types/YourContributionField'

export const initialState = {
  onPage: 0,
  pageData: <FormPage[]>[

    // DATA FORM 1: ABOUT YOU
    {
      isValidated: false,
      data: {
        [FIRST_NAME]: {
          value: undefined,
          isValidated: false,
        },
        [LAST_NAME]: {
          value: undefined,
          isValidated: false,
        },
        [PHONE_NUMBER]: {
          value: undefined,
          isValidated: false,
        },
        [EMAIL]: {
          value: undefined,
          isValidated: false,
        },
        [SUBURB_WORK]: {
          value: undefined,
          isValidated: false,
        },
        [STATE_FROM_SUBURB]: {
          value: undefined,
          isValidated: false,
        },
        [EMPLOYER]: {
          value: undefined,
          isValidated: false,
        },
        [EMPLOYER_SELECTED_OR_TYPED]: {
          value: undefined,
          isValidated: false,
        },
        [PAYROLL_NUMBER_REQUIRED]: {
          value: undefined,
          isValidated: false,
        },
        [PAYROLL_DEDUCTION_REQUIRED]: {
          value: undefined,
          isValidated: false,
        },
        [CANT_FIND_SUBURB_SELECTED]: {
          value: undefined,
          isValidated: false,
        },
        [WORK_PLACE_POSTCODE]: {
          value: undefined,
          isValidated: false,
        }
      } as FormDataType
    },
    // DATA FORM 2: YOUR WORK
    {
      isValidated: false,
      data: {
        [ADDRESS]: {
          value: undefined,
          isValidated: false,
        },
        [UNIT_NUMBER]: {
          value: undefined,
          isValidated: false,
        },
        [CITY]: {
          value: undefined,
          isValidated: false,
        },
        [STATE_OR_TERITORY]: {
          value: undefined,
          isValidated: false,
        },
        [POSTCODE]: {
          value: undefined,
          isValidated: false,
        },
        [GENDER]: {
          value: undefined,
          isValidated: false,
        },
        [EMPLOYMENT_STATUS]: {
          value: undefined,
          isValidated: false,
        },
        [DATE_OF_BIRTH]: {
          value: undefined,
          isValidated: false,
        },
        [EMPLOYMENT_STATUS_CASUAL]: {
          value: undefined,
          isValidated: false,
        },
        [YOUR_NEXT_OF_KIN_DATA]: {
          isValidated: false,
          value: {
            [KIN_FIRST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_LAST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_PHONE_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_RELATION]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_ADDRESS]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_UNIT_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_CITY]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_STATE_OR_TERITORY]: {
              value: undefined,
              isValidated: false,
            },
            [KIN_POSTCODE]: {
              value: undefined,
              isValidated: false,
            },
            [GUARDIAN_FIRST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [GUARDIAN_LAST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [GUARDIAN_PHONE_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
            [GUARDIAN_EMAIL]: {
              value: undefined,
              isValidated: false,
            },
          }
        },
      }
    },

    // DATA FORM 3: MEMBERSHIP
    {
      isValidated: false,
      data: {
        [PRE_EXISTING_ISSUES]: {
          value: undefined,
          isValidated: false,
        },
        [MEMBERSHIP_AGREEMENT]: {
          value: undefined,
          isValidated: false,
        },
      }
    },

    // DATA FORM 4: YOUR CONTRIBUTION
    {
      isValidated: false,
      data: {
        [BILLING_ADDRESS]: {
          value: undefined,
          isValidated: false,
        },
        [BILLING_ADDRESS_OPTION]: {
          value: undefined,
          isValidated: false,
        },

        [PAYMENT_METHOD_SELECTION]: {
          value: undefined,
          isValidated: false,
        },
        [DIFFERENT_BILLING_ADDRESS_DATA]: {
          isValidated: false,
          value: {
            [BILLING_FIRST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [BILLING_LAST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [BILLING_ADDRESS]: {
              value: undefined,
              isValidated: false,
            },
            [BILLING_UNIT_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
            [BILLING_CITY]: {
              value: undefined,
              isValidated: false,
            },
            [BILLING_STATE_OR_TERITORY]: {
              value: undefined,
              isValidated: false,
            },
            [BILLING_POSTCODE]: {
              value: undefined,
              isValidated: false,
            },
          }
        },

        [CREDITCARD_PAYMENT_TAB_DATA]: {
          isValidated: false,
          value: {
            [CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_FATZEBRA_AUTHKEY]: {
              value: undefined,
              isValidated: false,
            },
            [CREDITCARD_FATZEBRA_TOKEN]: {
              value: undefined,
              isValidated: true,
            },
            [CREDITCARD_PAYWAY_TOKEN]: {
              value: undefined,
              isValidated: false,
            },
          }
        },

        [BANKACCOUNT_PAYMENT_TAB_DATA]: {
          isValidated: false,
          value: {
            [BANKACCOUNT_PAYMENT_ACCOUNT_NAME]: {
              value: undefined,
              isValidated: false,
            },
            [BANKACCOUNT_PAYMENT_BSB]: {
              value: undefined,
              isValidated: false,
            },
            [BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
            [BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]: {
              value: undefined,
              isValidated: false,
            },
            [BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]: {
              value: undefined,
              isValidated: false,
            },
            [BANKACCOUNT_AGREE_TERM_AND_CONDITION]: {
              value: undefined,
              isValidated: false,
            },
          }
        },

        [PAY_DEDUCTION_PAYMENT_TAB_DATA]: {
          isValidated: false,
          value: {
            [PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE]: {
              value: undefined,
              isValidated: false,
            },
            [PAY_DEDUCTION_PAYMENT_NUMBER]: {
              value: undefined,
              isValidated: false,
            },
          }
        },

        [DIRECT_DEBIT_AGREEMENT]: {
          value: undefined,
          isValidated: false,
        },
      }
    },

  ],
  formSteps: {
    0: {
      previousStepsValid: true,
      hasSuccessfullyPosted: false
    },
    1: {
      previousStepsValid: false,
      hasSuccessfullyPosted: false
    },
    2: {
      previousStepsValid: false,
      hasSuccessfullyPosted: false
    },
    3: {
      previousStepsValid: false,
      hasSuccessfullyPosted: false
    }
  },
  formSubmitted: false
}

type State = typeof initialState

export type StepState = {
  0: {
    previousStepsValid: boolean,
    hasSuccessfullyPosted: boolean
  },
  1: {
    previousStepsValid: boolean,
    hasSuccessfullyPosted: boolean
  },
  2: {
    previousStepsValid: boolean,
    hasSuccessfullyPosted: boolean
  },
  3: {
    previousStepsValid: boolean,
    hasSuccessfullyPosted: boolean
  }
}

export type StepStateKeys = keyof StepState

const calculateAvailableSteps = (state: State): StepState => {
  return {
    0: {
      previousStepsValid: true,
      hasSuccessfullyPosted: state.formSteps[0].hasSuccessfullyPosted
    },
    1: {
      previousStepsValid: state.pageData[0].isValidated,
      hasSuccessfullyPosted: state.formSteps[1].hasSuccessfullyPosted
    },
    2: {
      previousStepsValid: state.pageData[0].isValidated && state.pageData[1].isValidated,
      hasSuccessfullyPosted: state.formSteps[2].hasSuccessfullyPosted
    },
    3: {
      previousStepsValid: state.pageData[0].isValidated && state.pageData[1].isValidated && state.pageData[2].isValidated,
      hasSuccessfullyPosted: state.formSteps[3].hasSuccessfullyPosted
    }
  }
}

const reducers = {
  next: (state: State) => {
    state.onPage = state.onPage + 1
  },

  goTo: (state: State, action: PayloadAction<number>) => {
    state.onPage = action.payload
  },

  backTo: (state: State, action: PayloadAction<number>) => {
    state.onPage = action.payload
    state.pageData = state.pageData
    state.formSteps = calculateAvailableSteps(state)
  },

  setSuccessfullyPosted: (state: State, action: PayloadAction<StepStateKeys>) => {
    state.formSteps[action.payload].hasSuccessfullyPosted = true
  },

  setUnsuccessfullyPosted: (state: State, action: PayloadAction<StepStateKeys>) => {
    state.formSteps[action.payload].hasSuccessfullyPosted = false
  },

  resetAllState: (state: State) => {
    state.onPage = initialState.onPage
    state.pageData = initialState.pageData
    state.formSubmitted = initialState.formSubmitted
    state.formSteps = initialState.formSteps
  },

  writeData: (state: State, action: PayloadAction<ActionPageDataInput>) => {
    state.pageData[action.payload.pageId].data = action.payload.data
    state.pageData[action.payload.pageId].isValidated = action.payload.isValidated
    state.formSteps = calculateAvailableSteps(state)
  },

  formSubmitted: (state: State) => {
    state.formSubmitted = true
  },

  writeValidateData: (state: State, action: PayloadAction<ActionPageDataInput>) => {
    state.pageData[action.payload.pageId].isValidated = action.payload.isValidated
    state.formSteps = calculateAvailableSteps(state)
  },

  writeYourWorkData: (state: State, action: PayloadAction<FieldData>) => {
    const { key, value, isValidated } = action.payload
    state.pageData[0] = {
      ...state.pageData[0],
      data: {
        ...state.pageData[0].data,
        [key]: {
          value: value,
          isValidated: isValidated
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeAboutYouData: (state: State, action: PayloadAction<FieldData>) => {
    const { key, value, isValidated } = action.payload
    state.pageData[1] = {
      ...state.pageData[1],
      data: {
        ...state.pageData[1].data,
        [key]: {
          value: value,
          isValidated: isValidated
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeForm2ForNewcastleValidated: (state: State, action: PayloadAction<FieldData>) => {
    const { key, value, isValidated } = action.payload
    state.pageData[1] = {
      ...state.pageData[1],
      data: {
        ...state.pageData[1].data,
        [key]: {
          isValidated: isValidated
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeNextOfKinData: (state: State, action: PayloadAction<FieldData>) => {
    const { key, value, isValidated } = action.payload
    const nextOfKinData = state.pageData[1].data[YOUR_NEXT_OF_KIN_DATA]
    state.pageData[1] = {
      ...state.pageData[1],
      data: {
        ...state.pageData[1].data,
        [YOUR_NEXT_OF_KIN_DATA]: {
          ...nextOfKinData,
          [key]: {
            value: value,
            isValidated: isValidated
          }
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeMembershipData: (state: State, action: PayloadAction<FieldData>) => {
    const { key, value, isValidated } = action.payload
    state.pageData[2] = {
      ...state.pageData[2],
      data: {
        ...state.pageData[2].data,
        [key]: {
          value: value,
          isValidated: isValidated
        }
      }
    }

  },

  writeYourContributionData: (state: State, action: PayloadAction<FieldData>) => {
    const { key, value, isValidated } = action.payload
    state.pageData[3] = {
      ...state.pageData[3],
      data: {
        ...state.pageData[3].data,
        [key]: {
          value: value,
          isValidated: isValidated
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeForm4BillingAddressInfo: (state: State, action: PayloadAction<FieldData>) => {
    const { payload } = action
    const differentBillingAddressData = state.pageData[3].data[DIFFERENT_BILLING_ADDRESS_DATA]
    const differentBillingAddressDataValue = differentBillingAddressData.value
    let differentBillingAddressValidated = payload.key !== BILLING_UNIT_NUMBER ?
      (payload.isValidated && differentBillingAddressDataValue !== undefined) :
      (true && differentBillingAddressDataValue !== undefined)

    if (differentBillingAddressDataValue !== undefined) {
      for (let [key, value] of Object.entries(differentBillingAddressDataValue)) {
        if (key === BILLING_UNIT_NUMBER || value === '') continue
        if (key !== payload.key && key !== BILLING_UNIT_NUMBER) {
          differentBillingAddressValidated = differentBillingAddressValidated && differentBillingAddressDataValue[key].isValidated
        }
      }
    }

    state.pageData[3] = {
      ...state.pageData[3],
      data: {
        ...state.pageData[3].data,
        [DIFFERENT_BILLING_ADDRESS_DATA]: {
          isValidated: differentBillingAddressValidated,
          value: {
            ...differentBillingAddressDataValue,
            [payload.key]: {
              value: payload.value,
              isValidated: payload.isValidated
            }
          }
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeForm4PaymentMethodValidated: (state: State, action: PayloadAction<{
    key: string, isValidated: boolean
  }>) => {
    const { key, isValidated } = action.payload
    state.pageData[3] = {
      ...state.pageData[3],
      data: {
        ...state.pageData[3].data,
        [key]: {
          isValidated: isValidated
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeForm4BankAccountTabData: (state: State, action: PayloadAction<FieldData>) => {
    const { payload } = action
    const bankAccountData = state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA]
    const bankAccountDataValue = bankAccountData.value
    const locationState = state.pageData[0].data[STATE_FROM_SUBURB].value

    let isBankAccountValidated = payload.isValidated && bankAccountDataValue !== undefined

    if (locationState === 'SA' || locationState === 'NT' || locationState === 'Broken Hill') {
      delete state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]
    }
    else if (locationState === 'QLD') {
      delete state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]
    }
    else if (locationState === 'WA') {
      state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION].value = 'Monthly'
      state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION].isValidated = true

      delete state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]
    }
    else if (locationState === 'TAS' && payload.key === BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION) {
      if (payload.value.toLowerCase() === 'monthly') {
        delete state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]
      } else {
        const value = state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION] ?? initialState.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]
        state.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA].value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION] = value
      }
    }
    if (bankAccountDataValue !== undefined) {
      for (let [key, value] of Object.entries(bankAccountDataValue)) {
        if (key !== payload.key) {
          isBankAccountValidated = isBankAccountValidated && bankAccountDataValue[key].isValidated
        }
      }
    }

    state.pageData[3] = {
      ...state.pageData[3],
      data: {
        ...state.pageData[3].data,
        [BANKACCOUNT_PAYMENT_TAB_DATA]: {
          isValidated: isBankAccountValidated,
          value: {
            ...bankAccountDataValue,
            [payload.key]: {
              value: payload.value,
              isValidated: payload.isValidated
            }
          }
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeForm4CreditCardTabData: (state: State, action: PayloadAction<FieldData>) => {
    const { payload } = action
    const creditCardData = state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA]
    const creditCardDataValue = creditCardData.value
    const locationState = state.pageData[0].data[STATE_FROM_SUBURB].value
    let isCreditCardValidated = payload.isValidated && creditCardDataValue !== undefined

    // Remove payment provider specific state fields for non participating branches

    if (locationState !== 'SA' && locationState !== 'NT' && locationState !== 'Broken Hill' &&
      locationState !== 'WA' && locationState !== 'QLD' && locationState !== 'TAS') {
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_FATZEBRA_AUTHKEY]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_FATZEBRA_TOKEN]
    }
    if (locationState !== 'Newcastle') {
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYWAY_TOKEN]
    }

    if (locationState === 'SA' || locationState === 'NT' || locationState === 'Broken Hill') {
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_FATZEBRA_TOKEN]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]
    }
    else if (locationState === 'QLD') {
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_FATZEBRA_TOKEN]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]
    }
    else if (locationState === 'TAS') {
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_FATZEBRA_TOKEN]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]

      if (payload.key === CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION) {
        if (payload.value.toLowerCase() === 'monthly') {
          delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]
        } else {
          const value = state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION] ?? initialState.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]
          state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION] = value
        }
      }
    }
    else if (locationState === 'Newcastle') {
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]
    }
    else if (locationState === 'WA') {
      state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION].value = 'Monthly'
      state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION].isValidated = true

      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_FATZEBRA_TOKEN]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]
      delete state.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA].value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]
    }

    if (creditCardDataValue !== undefined) {
      for (let [key, value] of Object.entries(creditCardDataValue)) {
        if (key !== payload.key) {
          isCreditCardValidated = isCreditCardValidated && creditCardDataValue[key].isValidated
        }
      }
    }

    state.pageData[3] = {
      ...state.pageData[3],
      data: {
        ...state.pageData[3].data,
        [CREDITCARD_PAYMENT_TAB_DATA]: {
          isValidated: isCreditCardValidated,
          value: {
            ...creditCardDataValue,
            [payload.key]: {
              value: payload.value,
              isValidated: payload.isValidated
            }
          }
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },

  writeForm4PayDeductionTabData: (state: State, action: PayloadAction<FieldData>) => {
    const { payload } = action
    const payDeductionData = state.pageData[3].data[PAY_DEDUCTION_PAYMENT_TAB_DATA]
    const payDeductionDataValue = payDeductionData.value
    let isPayDeductionValidated = payload.isValidated && payDeductionDataValue !== undefined
    const payrollNumberRequired = state.pageData[0].data[PAYROLL_NUMBER_REQUIRED].value

    if (payDeductionDataValue !== undefined) {
      for (let [key, value] of Object.entries(payDeductionDataValue)) {
        if (payrollNumberRequired === 'false' || payrollNumberRequired === '') {
          isPayDeductionValidated === isPayDeductionValidated && payDeductionDataValue[PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE].value
        }
        else if (key !== payload.key) {
          isPayDeductionValidated = isPayDeductionValidated && payDeductionDataValue[key].isValidated
        }
      }
    }

    state.pageData[3] = {
      ...state.pageData[3],
      data: {
        ...state.pageData[3].data,
        [PAY_DEDUCTION_PAYMENT_TAB_DATA]: {
          isValidated: isPayDeductionValidated,
          value: {
            ...payDeductionDataValue,
            [payload.key]: {
              value: payload.value,
              isValidated: payload.isValidated
            }
          }
        }
      }
    }
    state.formSteps = calculateAvailableSteps(state)
  },
}

export const FormHighOrderSlice = createSlice<State, typeof reducers>({
  name: 'FormHighOrderComponent',
  initialState,
  reducers,
})

export const {
  next, goTo, backTo, resetAllState, writeData, formSubmitted, writeValidateData,
  setSuccessfullyPosted, setUnsuccessfullyPosted, writeYourWorkData, writeAboutYouData, writeNextOfKinData,
  writeForm2ForNewcastleValidated,
  writeMembershipData,
  writeYourContributionData,
  writeForm4BillingAddressInfo,
  writeForm4BankAccountTabData,
  writeForm4CreditCardTabData,
  writeForm4PayDeductionTabData,
  writeForm4PaymentMethodValidated
} = FormHighOrderSlice.actions

export default FormHighOrderSlice.reducer
