import { configureStore } from '@reduxjs/toolkit'
import { default as counter } from '../features/counter'
import { default as duplicates } from '../features/duplicates'
import { default as FormHighOrder } from '../components/FormHighOrder/features/index'

export const store = configureStore({
  reducer: {
    counter,
    duplicates,
    FormHighOrder,
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store


