//https://github.com/gatsbyjs/gatsby/issues/12399
import 'core-js';
import 'babel-polyfill';
import { polyfill } from 'es6-promise';
import fetch from 'isomorphic-fetch';
global.Promise = require('es6-promise').Promise;

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {

}
polyfill();

export { wrapRootElement } from './gatsby-wrapper'
