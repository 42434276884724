import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

const initialState = {
  isMultiBranch: false,
}

type State = typeof initialState

const reducers = {
  setIsMultiBranch: (state: State, action) => {
    state.isMultiBranch = action.payload
  },
  reset: (state: State) => {
    state.isMultiBranch = false
  }
}

export const duplicatesSlice = createSlice<State, typeof reducers>({
  name: 'duplicates',
  initialState,
  reducers,
})

export const { setIsMultiBranch, reset } = duplicatesSlice.actions

export default duplicatesSlice.reducer
